import React from 'react'

export const WateringCan = () => (
    <svg x='0px' y='0px' viewBox='0 0 451.272 451.272'>
        <g>
            <g>
                <path
                    style={{ fill: '#229CD2' }}
                    d='M134.628,344.499C60.394,344.499,0,284.105,0,209.87S60.394,75.24,134.628,75.24    c74.235,0,134.629,60.395,134.629,134.63S208.864,344.499,134.628,344.499z M134.628,99.007    c-61.129,0-110.86,49.733-110.86,110.862s49.731,110.86,110.86,110.86S245.49,270.999,245.49,209.87    S195.757,99.007,134.628,99.007z'
                />
                <path
                    style={{ opacity: 0.3, fill: '#222222' }}
                    d='M261.253,164.13h-25.645c2.977,6.545,5.334,13.428,6.999,20.575h24.282    C265.548,177.637,263.656,170.763,261.253,164.13z'
                />
                <path
                    style={{ opacity: 0.3, fill: '#222222' }}
                    d='M57.429,289.351V320.1c4.24,2.978,8.657,5.725,13.235,8.206v-27.94    C65.969,297.038,61.547,293.351,57.429,289.351z'
                />
            </g>
            <g>
                <g>
                    <polygon
                        style={{ fill: '#229CD2' }}
                        points='370.801,101.751 354.459,149.728 393.704,188.97 441.681,172.629    '
                    />
                    <polygon
                        style={{ opacity: 0.2, fill: '#222222' }}
                        points='374.08,169.35 393.704,188.97 441.681,172.629 406.241,137.19    '
                    />

                    <rect
                        x='397.576'
                        y='84.357'
                        transform='matrix(-0.7071 0.7071 -0.7071 -0.7071 792.0532 -56.7847)'
                        style={{ fill: '#67CFE3' }}
                        width='20.421'
                        height='102.575'
                    />

                    <rect
                        x='415.709'
                        y='128.132'
                        transform='matrix(-0.7071 0.7071 -0.7071 -0.7071 835.8284 -38.652)'
                        style={{ opacity: 0.2, fill: '#222222' }}
                        width='20.421'
                        height='51.289'
                    />
                </g>
                <g>
                    <rect
                        x='187.69'
                        y='226.029'
                        transform='matrix(0.7071 -0.7071 0.7071 0.7071 -87.3883 282.1392)'
                        style={{ fill: '#229CD2' }}
                        width='218.372'
                        height='41.054'
                    />

                    <rect
                        x='293.87'
                        y='144.628'
                        transform='matrix(-0.7071 -0.7071 0.7071 -0.7071 339.7152 648.3425)'
                        style={{ opacity: 0.2, fill: '#222222' }}
                        width='20.527'
                        height='218.372'
                    />

                    <rect
                        x='367.742'
                        y='151.449'
                        transform='matrix(-0.7071 0.7071 -0.7071 -0.7071 755.726 30.937)'
                        style={{ opacity: 0.3, fill: '#222222' }}
                        width='7.428'
                        height='41.054'
                    />
                </g>
            </g>
            <g>
                <rect
                    x='70.664'
                    y='181.021'
                    style={{ fill: '#229CD2' }}
                    width='206.399'
                    height='195.012'
                />
                <g>
                    <rect
                        x='70.664'
                        y='263.636'
                        style={{ fill: '#67CFE3' }}
                        width='206.399'
                        height='15'
                    />
                    <rect
                        x='70.664'
                        y='289.712'
                        style={{ fill: '#67CFE3' }}
                        width='206.399'
                        height='15'
                    />
                    <rect
                        x='70.664'
                        y='315.787'
                        style={{ fill: '#67CFE3' }}
                        width='206.399'
                        height='15'
                    />
                </g>
                <rect
                    x='70.664'
                    y='181.021'
                    style={{ opacity: 0.2, fill: '#222222' }}
                    width='206.399'
                    height='21.802'
                />
                <rect
                    x='70.664'
                    y='181.021'
                    style={{ opacity: 0.2, fill: '#222222' }}
                    width='103.2'
                    height='195.012'
                />
                <g>
                    <rect
                        x='64.074'
                        y='171.976'
                        style={{ fill: '#67CFE3' }}
                        width='219.581'
                        height='20.159'
                    />
                    <rect
                        x='64.074'
                        y='171.976'
                        style={{ opacity: 0.2, fill: '#222222' }}
                        width='109.79'
                        height='20.159'
                    />
                </g>
            </g>
        </g>
    </svg>
)
