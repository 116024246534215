import React from 'react'
import { Metro, Weather, Clock, PlantWatering } from './'
import '../styles/App.css'

function App() {
    return (
        <div className='App'>
            <section className='main main-split'>
                <Metro />
                <Weather />
            </section>
            <section className='main main-split'>
                <Clock />
                <h1>Anteckningar</h1>
            </section>
            <section className='main main-split'>
                <h1>Lisas Kalender</h1>
                <h1>Arvids Kalender</h1>
            </section>
            <section className='main'>
                <PlantWatering />
            </section>
        </div>
    )
}

export default App
