import React, { useState, useEffect } from 'react'
import { fetchWeatherData, useInterval, MS_PER_MINUTE } from '../helpers/'
import { Loading } from './'
import '../styles/Weather.css'

const INITIAL_STATE = []

export const Weather = () => {
    const [weather, setWeather] = useState(INITIAL_STATE)

    useEffect(() => {
        fetchWeatherData().then((w) => setWeather(w))
    }, [])

    useInterval(() => {
        fetchWeatherData().then((w) => setWeather(w))
    }, MS_PER_MINUTE)

    return (
        <div className='weather-container'>
            <h1>Väder</h1>
            {weather.length > 0 ? (
                weather.map((forecast, index) => (
                    <WeatherRow key={forecast.time} {...forecast} />
                ))
            ) : (
                <Loading />
            )}
        </div>
    )
}

const WeatherRow = ({ time, parameters }) => {
    const weatherIcon = `./assets/img/weather/${parameters.Wsymb2[0]}.svg`
    return (
        <div className='weather-row'>
            <div>{time}</div>
            <div>{parameters.t[0]}&#176;C</div>
            <img src={weatherIcon} alt='' />
        </div>
    )
}
