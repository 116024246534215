import React, { useContext } from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
}

export class Firebase {
    constructor() {
        if (process.env.NODE_ENV === 'development') {
            console.log('Initializing firebase in dev environment')
            console.log(process.env.REACT_APP_AUTH_DOMAIN)
        }
        firebase.initializeApp(config)
        this.db = firebase.firestore()
        this.auth = firebase.auth()
        this.storage = firebase.storage()
    }

    // *** Auth API ***

    doCreateUserWithEmailAndPassword = (email, password) =>
        this.auth.createUserWithEmailAndPassword(email, password)

    doSignInWithEmailAndPassword = (email, password) =>
        this.auth.signInWithEmailAndPassword(email, password)

    doSendEmailVerification = () =>
        this.auth.currentUser.sendEmailVerification()

    doSignOut = () => this.auth.signOut()

    doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email)

    doPasswordUpdate = (password) =>
        this.auth.currentUser.updatePassword(password)

    onAuthStateChanged = (next, fallback) => {
        return this.auth.onAuthStateChanged((user) => {
            if (user) {
                next(user)
            } else {
                fallback()
            }
        })
    }

    user = (uid) => this.db.ref(`users/${uid}`)

    // *** DB API ***

    doAddWateringDate = () => {
        const date = new Date().toLocaleDateString()
        return this.db
            .collection('plant-watering')
            .doc('all-dates')
            .update({
                latest: date,
                dates: firebase.firestore.FieldValue.arrayUnion(date),
            })
            .then(() => date)
    }

    doGetLatestWateringDate = () =>
        this.db
            .collection('plant-watering')
            .doc('all-dates')
            .get()
            .then((res) => {
                if (res.exists) {
                    return res.data()
                }
                return { latest: 'Your plants are dead' }
            })
}

export const FirebaseContext = React.createContext({})

export const useFirebase = () => useContext(FirebaseContext)

export default Firebase
