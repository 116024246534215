import React, { useState, useEffect } from 'react'
import { parsedFetch, useInterval, MS_PER_MINUTE, METRO_URL } from '../helpers/'
import { Loading } from './'
import '../styles/Metro.css'

const INITIAL_STATE = {
    update: '',
    metros: [],
    deviations: [],
}

export const Metro = () => {
    const [metroInfo, setMetroInfo] = useState(INITIAL_STATE)

    useEffect(() => {
        parsedFetch(METRO_URL).then(handleUpdateInfo)
    }, [])

    useInterval(() => {
        parsedFetch(METRO_URL).then(handleUpdateInfo)
    }, MS_PER_MINUTE)

    const handleUpdateInfo = (info) => {
        if (info.status === 0) setMetroInfo(info)
    }

    return (
        <div className='metro-container'>
            <h1>Tunnelbana</h1>
            {metroInfo.metros.length > 0 ? (
                metroInfo.metros.map((metro) => (
                    <MetroRow key={metro.JourneyNumber} metro={metro} />
                ))
            ) : (
                <Loading />
            )}
            {/*metroInfo.deviations.map((deviation, index) => (
                <Deviation key={deviation.Deviation.Text} text={deviation.Deviation.Text} />
            ))*/}
        </div>
    )
}

const MetroRow = ({ metro }) => <p className='metro-row'>{metro.DisplayTime}</p>

// const Deviation = ({ text }) => <p>{text}</p>
