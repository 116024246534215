import { API_URL_SMHI, NUM_DISPLAY } from './'

export const fetchWeatherData = () => {
    return parsedFetch(API_URL_SMHI).then((res) =>
        res.timeSeries
            .filter((el, i) => i % 3 === 0)
            .slice(0, NUM_DISPLAY)
            .map((obj) => ({
                time: new Date(obj.validTime).toLocaleTimeString().slice(0, 2),
                parameters: obj.parameters.reduce(
                    (ob, item) =>
                        Object.assign(ob, {
                            [item.name]: item.values,
                        }),
                    {}
                ),
            }))
    )
}

export const parsedFetch = (url) => fetch(url).then((res) => res.json())
