import React, { useEffect, useState } from 'react'
import { useFirebase } from '../helpers'
import { WateringCan } from './'
import '../styles/PlantWatering.css'

export const PlantWatering = () => {
    const firebase = useFirebase()
    const [waterDate, setWaterDate] = useState('')
    useEffect(() => {
        firebase.doGetLatestWateringDate().then((dates) => {
            console.log(dates)
            setWaterDate(dates.latest)
        })
    }, [])

    const handleWateredPlants = () => {
        firebase.doAddWateringDate().then(setWaterDate)
    }
    return (
        <div>
            <h1>Växter</h1>
            <h3>
                Vattnades senast
                <br />
                <p>{waterDate}</p>
            </h3>
            <button
                onClick={handleWateredPlants}
                className='watering-can-button'
            >
                <WateringCan />
            </button>
        </div>
    )
}
