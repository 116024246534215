import React from 'react'
import '../styles/Loading.css'

export const Loading = () => (
    <div className='loading'>
        <div className='lds-ellipsis'>
            <div />
            <div />
            <div />
            <div />
        </div>
    </div>
)
