export const MS_PER_SECOND = 1000
export const MS_PER_MINUTE = 60 * MS_PER_SECOND
export const MS_PER_HOUR = 60 * MS_PER_MINUTE
export const TIME = [MS_PER_HOUR, MS_PER_MINUTE, MS_PER_SECOND]

export const METRO_URL = 'https://sl-dashboard.edenheim.workers.dev/'

export const NUM_DISPLAY = 5

export const API_URL_SMHI =
    'https://opendata-download-metfcst.smhi.se/api/category/pmp3g/version/2/geotype/point/lon/18.003197/lat/59.307204/data.json'

export const PRECIPITATION_CATEGORIES = {
    0: 'Sol',
    1: 'Snö',
    2: 'Snöblandat regn',
    3: 'Regn',
    4: 'Duggregn',
    5: 'Pisskallt regn',
    6: 'Svinkallt duggregn'
}

export const WEATHER_CATEGORIES = {
    1: 'Klar himmel',
    2: 'Nästan klar himmel',
    3: 'Växlande molnighet',
    4: 'Halvklar himmel',
    5: 'Molnigt',
    6: 'Overcast',
    7: 'Dimma',
    8: 'Lätt regn',
    9: 'Lagom regn',
    10: 'Kraftigt regn',
    11: 'Åska',
    12: 'Light sleet showers',
    13: 'Moderate sleet showers',
    14: 'Heavy sleet showers',
    15: 'Light snow showers',
    16: 'Moderate snow showers',
    17: 'Heavy snow showers',
    18: 'Light rain',
    19: 'Moderate rain',
    20: 'Heavy rain',
    21: 'Thunder',
    22: 'Light sleet',
    23: 'Moderate sleet',
    24: 'Heavy sleet',
    25: 'Light snowfall',
    26: 'Moderate snowfall',
    27: 'Heavy snowfall'
}
